/* eslint-disable jsx-a11y/label-has-associated-control */
import RoundedIcon from 'components/RoundedIcon'
import addIcon from '../asset/add.svg'

function ChatField() {
  return (
    <div
      className={'grid grid-cols-[auto,1fr] gap-2 px-2'}
    >
      <div
        className={'flex bg-message-gray p-1 rounded-full'}
      >
        <img
          src={addIcon}
          alt={'addIcon'}
        />
      </div>

      <label
        className={'input input-bordered font-body rounded-full flex items-center gap-2 py-1 text-xs h-full w-full bg-white-brand'}
      >
        <input
          type={'text'}
          placeholder={'AGSMessage'}
          className={'grow'}
          disabled
        />

        <RoundedIcon
          className={'text-gray-400 text-base'}
        >
          {'mic'}
        </RoundedIcon>
      </label>
    </div>
  )
}

export default ChatField
