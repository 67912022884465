function OwnChat({ isSeen, children }) {
  return (
    <div
      className={'chat chat-end font-body'}
    >
      <div
        className={'chat-bubble bg-message-green text-white-brand text-xs max-w-[75%] min-h-[2rem]'}
      >
        {children}
      </div>

      {isSeen ? (
        <div
          className={'chat-footer opacity-50 text-[0.5rem]'}
        >
          {'Seen at 12:30'}
        </div>
      ) : null}
    </div>
  )
}

export default OwnChat
