/* eslint-disable import/no-extraneous-dependencies */
import debounce from 'debounce'
import Button from 'components/Button'
import Logo from 'components/Logo'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import NavItem from './components/NavItem'

const menuItems = [
  {
    id: 1,
    title: 'Social Events',
    url: 'https://instagram.com/agssmc',
    icon: 'group',
  },
  {
    id: 2,
    title: 'AGS Store',
    url: 'https://www.bonfire.com/store/agssmc/',
    icon: 'shopping_cart',
  },
  {
    id: 3,
    title: 'Newsletter',
    url: 'https://newsletter.agssmc.com/',
    icon: 'feed',
  },
]

function Navbar() {
  const [
    addShadow,
    setAddShadow,
  ] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const onScrollHandler = debounce(() => {
      if (window.scrollY >= 20) {
        setAddShadow(true)
      } else {
        setAddShadow(false)
      }
    }, 10)
    window.addEventListener('scroll', onScrollHandler)

    return () => {
      window.removeEventListener('scroll', onScrollHandler)
    }
  })
  return (
    <div
      className={`sticky top-0 z-[13] bg-main transition-shadow ${addShadow ? 'shadow-sm' : ''}`}
    >
      <div
        className={'container'}
      >
        <div
          className={'navbar'}
        >
          <Button
            className={'flex-none'}
            isTransparent
            onClick={() => navigate('/')}
          >
            <Logo
              className={'h-12 mr-2'}
            />

            <h1
              className={'font-bold text-xl'}
            >
              {'Alpha Gamma Sigma '}

              <span
                className={'text-white'}
              >
                {'SMC'}
              </span>
            </h1>
          </Button>

          <div
            className={'hidden md:block flex-1'}
          />

          <div
            className={'hidden md:flex z-[2] gap-12 pr-4 flex-none list-none'}
          >
            {
              menuItems.map((i) => (
                <NavItem
                  key={i.id}
                  url={i.url}
                  icon={i.icon}
                >
                  {i.title}
                </NavItem>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
