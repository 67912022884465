import Avatar from './components/Avatar'
import Chat from './components/Chat'
import Phone from './components/Phone'
import OwnChat from './components/OwnChat'
import ProfileHeader from './components/ProfileHeader'
import AgsChat from './components/AgsChat'
import requirementData from './data'
import ChatField from './components/ChatField'
import Divider from './components/Divider'

function Requirement() {
  return (
    <div
      className={'mt-4 grid md:grid-cols-2 md:gap-8'}
    >
      <Phone>
        <ProfileHeader>
          <div
            className={'avatar online'}
          >
            <Avatar
              size={8}
            />
          </div>

          <div
            className={'text-lg font-bold'}
          >
            {'AGS SMC'}
          </div>
        </ProfileHeader>

        <Chat>
          <p
            className={'text-center font-light text-[0.5rem] text-gray-500'}
          >
            {'Thursday 11:30 PM'}
          </p>

          <OwnChat>
            {'Hey :))'}
          </OwnChat>

          <OwnChat>
            {'What do I need for the Transcript Notation again??'}
          </OwnChat>

          <AgsChat>
            {'Hey, you\'ll need the following things:'}

            <ul
              className={'ml-4 list-disc'}
            >
              {requirementData.map((r) => (
                <li
                  className={'mt-1'}
                  key={r}
                >
                  {r}
                </li>
              ))}
            </ul>
          </AgsChat>
        </Chat>

        <OwnChat
          isSeen
        >
          {'Omg, thank you!'}
        </OwnChat>

        <ChatField />

        <Divider />
      </Phone>

      <div
        className={'flex justify-center items-center'}
      >
        <p
          className={'mt-4 md:!mt-0 font-body text-xl md:text-3xl lg:text-5xl md:font-bold md:leading-[3rem] lg:leading-[4rem] text-center items-end'}
        >
          {'Need additional information?'}

          <br />

          {'Questions or comments?'}

          <br />

          {'Please feel free to contact us at: '}

          <a
            className={'underline text-white-brand'}
            href={'mailto:askquestion4ags@gmail.com'}
          >
            {'askquestion4ags@gmail.com'}
          </a>
        </p>
      </div>

    </div>
  )
}

export default Requirement
