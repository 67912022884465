function NavItem({ children, url, icon }) {
  return (
    <li>
      <a
        href={url}
      >
        <button
          type={'button'}
          className={'flex gap-2 items-center text-xl hover:text-yellow-brand'}
        >
          <span
            className={'material-symbols-outlined'}
          >
            {icon}
          </span>

          {children}
        </button>
      </a>
    </li>
  )
}

export default NavItem
